import React from 'react';
import CaseStudyLists from "../case-study";
import FilterElements from "./filter-elements";
import TrackVisibility from 'react-on-screen';

const FilterSort = ({data}) => {
    const {dataFilter, filterList} = data

    return (
        <>
            <div className={'orange tooltip-popup scale-up-tl flex-container-nowrap__sb'}>
                {!!filterList && Object.keys(filterList).map((entity) => (
                        (entity === 'caseStudyIndustries') ?
                            <div className={'filter-result-wrapper white'}
                                 key={entity}>
                                <FilterElements data={filterList[entity]} title={'Industries'}/>
                            </div> :
                            (entity === 'caseStudyRegions') ?
                                <div className={'filter-result-wrapper white'}
                                     key={entity}>
                                    <FilterElements data={filterList[entity]} title={'Regions'}/>
                                </div> :
                                (entity === 'caseStudyTechnologies') ?
                                    <div className={'filter-result-wrapper white'}
                                         key={entity}>
                                        <FilterElements data={filterList[entity]} title={'Technologies'}/>
                                    </div> :
                                    false
                    )
                )}
            </div>
            <TrackVisibility once offset={2000}>
                <CaseStudyLists data={dataFilter}
                                showButton={false}
                                showNumberItems={false}/>
            </TrackVisibility>
        </>
    )
}

const FilterList = ({data, filterList}) => {
    const {nodes} = data;
    const [dataFilter, setDataFilter] = React.useState({nodes});

    return (
        <>
            <div className={`tooltip-popup-wrapper`}>
                <FilterSort
                    data={{dataFilter, setDataFilter, filterList}}/>
            </div>
        </>
    )
}

export default FilterList