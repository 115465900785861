import React from 'react'

const FilterElements = ({data, title}) => {
    return (
        <>
            <label className={'h5-title'}>{title}</label>
            <ul className={'filter-result-items'}>
                <li key={`check-all__${title}`}>
                    <input className={'input white'}
                           value={`check-all__${title}`}
                           id={`check-all__${title}`}
                           type={'checkbox'}/>
                    <label htmlFor={`check-all__${title}`}
                           className={'accept-policy h6-title'}>
                        <span>All</span>
                    </label>
                </li>
                {!!data?.nodes && data.nodes.map((entity) => (
                    <li key={entity.id}>
                        <input className={'input white'}
                               value={entity.id}
                               id={entity.id}
                               type={'checkbox'}/>
                        <label htmlFor={entity.id}
                               className={'accept-policy h6-title'}>
                            <span>{entity.name}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default FilterElements