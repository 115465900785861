import React, { useEffect, useRef, useState } from "react"
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { normalizePath } from "../../utils/get-url-path";
import { OptionList } from "./options-list";
import sanitizeOptions from "../../helper/sanitizeOptions";
import Img from "gatsby-image";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";

const TILT_MAX_ANGLE_X = 13;
const TILT_MAX_ANGLE_Y = 12;
const AUTORESET_ON_LEAVE = true;

export const Item = ({ setVisible, showNumberItems, i, page, startAnimation }) => {
    const sanitizeOpt = sanitizeOptions();
    let width = null;
    let height = null;
    let left = null;
    let top = null;
    let clientPosition = {
        x: 0,
        y: 0,
        xPercentage: 0,
        yPercentage: 0,
    };
    let tiltAngleX = 0;
    let tiltAngleY = 0;
    let updateAnimationId = null;
    let transitionTimeoutId = null;
    const wrapperEl = useRef(null);
    const [windowSize, setWindowSize] = useState(false);
    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;

    useEffect(() => {
        setSizeAndPosition();
        window.addEventListener('deviceorientation', onMove);
        (window.innerWidth < constants.MOBILE_WIDTH) ? setWindowSize(false) : setWindowSize(true);

        return window.addEventListener('deviceorientation', onMove);
    }, []);

    const setSizeAndPosition = () => {
        if (wrapperEl?.current) {
            const rect = wrapperEl.current.getBoundingClientRect();
            width = wrapperEl.current.offsetWidth;
            height = wrapperEl.current.offsetHeight;
            left = rect.left;
            top = rect.top;
        }
    };

    const onEnter = () => {
        if (!wrapperEl) {
            return;
        }

        wrapperEl.current.style.willChange = 'transform';
        setTransition();
    };

    const onMove = (event) => {
        if (!wrapperEl) {
            return;
        }
        if (updateAnimationId) {
            cancelAnimationFrame(updateAnimationId);
        }
        setSizeAndPosition();
        processInput(event);
        update(event.type);
        updateAnimationId = requestAnimationFrame(renderFrame);
    };

    const onLeave = () => {
        setTransition();

        const autoResetEvent = new CustomEvent('autoreset');
        onMove(autoResetEvent);
    };

    const processInput = (event) => {
        switch (event.type) {
            case 'mousemove':
                clientPosition.x = event.clientX;
                clientPosition.y = event.clientY;
                break;
            case 'touchmove':
                clientPosition.x = event.touches[0].pageX;
                clientPosition.y = event.touches[0].pageY;
                break;
            case 'deviceorientation':
                processInputDeviceOrientation(event);
                break;
            case 'autoreset':
                if (AUTORESET_ON_LEAVE) {
                    tiltAngleX = 0;
                    tiltAngleY = 0;
                }
                break;
            default:
                tiltAngleX = 0;
                tiltAngleY = 0;
        }
    };

    const processInputDeviceOrientation = (event) => {
        if (!event.gamma || !event.beta) {
            return;
        }

        const angleX = event.beta; // motion of the device around the x axis in degree in the range:[-180,180]
        const angleY = event.gamma; // motion of the device around the y axis in degree in the range:[-90,90]

        clientPosition.xPercentage = (angleX / TILT_MAX_ANGLE_X) * 100;
        clientPosition.yPercentage = (angleY / TILT_MAX_ANGLE_Y) * 100;
    };

    const update = (eventType) => {
        if (eventType === 'autoreset') {
            return;
        }

        if (eventType !== 'deviceorientation') {
            const { x, y } = clientPosition;
            clientPosition.xPercentage = ((y - top) / height) * 200 - 100;
            clientPosition.yPercentage = ((x - left) / width) * 200 - 100;
        }

        clientPosition.xPercentage = Math.min(Math.max(clientPosition.xPercentage, -100), 100);
        clientPosition.yPercentage = Math.min(Math.max(clientPosition.yPercentage, -100), 100);

        tiltAngleX = (clientPosition.xPercentage * TILT_MAX_ANGLE_X) / 100;
        tiltAngleY = -((clientPosition.yPercentage * TILT_MAX_ANGLE_Y) / 100);
    };

    const renderFrame = () => {
        if (wrapperEl.current) {
            wrapperEl.current.style.transform = `perspective(1000px) rotateX(${tiltAngleX}deg) rotateY(${tiltAngleY}deg) scale3d(1.26,1.2,1.2)`;
        }
    };

    const setTransition = () => {
        const duration = 1800;
        wrapperEl.current.style.transition = `all ${duration}ms cubic-bezier(.03,.98,.52,.99)`;

        transitionTimeoutId = setTimeout(() => {
            if (wrapperEl.current) {
                wrapperEl.current.style.transition = '';
            }
        }, duration);
    };


    return (
        <AniLink
            className={'case-study-item item visible'}
            style={(setVisible(showNumberItems, i))}
            data-animate="animate-container"
            key={page.id}
            paintDrip
            duration={1}
            hex="#ed5d2b"
            to={normalizePath(page.link)}
        >
            {(i < 1) && (
                <div
                    className={'case-study-separator top'}
                    style={{
                        width: startAnimation ? '100%' : '0',
                        transition: 'all 1s ease'
                    }}
                />
            )}
            <div
                className={'case-study-separator bottom'}
                style={{
                    width: startAnimation ? '100%' : '0',
                    transition: 'all 1s ease'
                }}
            />
            <div
                className={'case-study-link flex-container-nowrap__sb'}>
                <div className={'flex-item case-study-title'}>
                    {!!page?.caseStudyTechnologies?.nodes.length && (
                        <div className={'paragraph-secondary minor-title black'}>
                            <OptionList data={page.caseStudyTechnologies}
                                        className={'black'}/>
                        </div>
                    )}
                    <h4 className={'h4-title major-title h4-title-bold-services black'}
                        data-animated-group="content">
                        {page.title}
                    </h4>
                    {isMobile && !!page?.info?.gridImage?.remoteFile?.childImageSharp && (
                        <div className={'image-mobile'}>
                            <Img fluid={page.info.gridImage.remoteFile.childImageSharp.fluid}/>
                        </div>
                    )}
                </div>
                <div className={'flex-item case-study-tags'}>
                    {!!page?.caseStudyRegions?.nodes.length && (
                        <div className={'tag-item region'}
                             >
                                    <span className={'paragraph-secondary black'}>
                                        Region:
                                    </span>
                            <OptionList data={page.caseStudyRegions}
                                        className={'black'}/>
                        </div>
                    )}
                    {!!page?.caseStudyIndustries?.nodes.length && (
                        <div className={'tag-item industry'}
                             data-animated-group="content">
                                    <span className={'paragraph-secondary black'}>
                                        Industries:
                                    </span>
                            <OptionList data={page.caseStudyIndustries}
                                        className={'black'}/>
                        </div>
                    )}

                    {isMobile && <div className={'view-case'}>
                        <AniLink
                            paintDrip
                            duration={1}
                            hex="#ed5d2b"
                            className={'possibilities-link mc-link mc-button-secondary link-font-styles right'}
                            to={normalizePath(page.link)}
                        >
                            <span>Learn more</span>
                        </AniLink>
                    </div>
                    }
                </div>

                <div className={'flex-item case-study-description paragraph-primary black'}
                     data-animated-group="content">
                    <div className={'case-study-image absolute'}>
                        {!isMobile && !!page?.info?.gridImage?.remoteFile?.childImageSharp && (
                            <Img fluid={page.info.gridImage.remoteFile.childImageSharp.fluid}/>
                        )}
                    </div>
                    {page?.info?.aboutClientShort && (
                        <p className={'body-font-styles'} dangerouslySetInnerHTML={{ __html: sanitizeHtml(page.info.aboutClientShort, sanitizeOpt.title) }}/>
                    )}
                </div>

            </div>
        </AniLink>
    )
}


