import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby"
import Layout from '../components/layout';
import TitleLarge from "../components/title-large-content";
import FilterList from "../components/case-study-filter";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import prevNextPaginationButtons from "../helper/prevNextPaginationButtons";
import * as constants from "../helper/constants";
import useWindowSize from "../hooks/useWindowSize";

export default ({ data, pageContext }) => {
    const {
        customFields,
        caseStudy,
        caseStudyIndustries,
        caseStudyRegions,
        caseStudyTechnologies
    } = data;
    const filterList = { caseStudyTechnologies, caseStudyRegions, caseStudyIndustries };
    const { caseStudiesCustomFields, seo, additionalMetaTag, title } = customFields;
    const { caseTitleLargeContent } = caseStudiesCustomFields;
    // Set metadata
    const seoData = { ...{ seo }, additionalMetaTag, title };
    //Pagination
    const prevButton = 'case-studies-previous';
    const nextButton = 'case-studies-next';
    const keyPagination = process.env.KEY_PAGINATION_CASE_STADIES;
    const [paginateButtons, setPaginateButtons] = useState(false);
    useEffect(() => {
        const prevButtonEl = document.getElementsByClassName(prevButton);
        const nextButtonEl = document.getElementsByClassName(nextButton);
        const paginateButtonsEl = { prevButtonEl, nextButtonEl };
        setPaginateButtons(paginateButtonsEl);
    }, [prevButton, nextButton]);

    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;
    const rangeDisplayed = isMobile && pageContext.totalPages > 3 ? 1 : 2;

    return (
        <Layout
            seoData={seoData}>
            <Helmet
                bodyAttributes={{
                    class: 'case-study-wrapper dark-header'
                }}
            />
            <div className={'page-content case-studies inner-pages'}>
                <TitleLarge
                    data={caseTitleLargeContent}/>

                <FilterList
                    filterList={filterList}
                    data={caseStudy}/>

                {pageContext && pageContext.totalPages > 1 && (
                    <div className={'pagination-wrapper'}>
                        <ReactPaginate
                            previousClassName={prevButton}
                            nextClassName={nextButton}
                            previousLabel={
                                <span className={'pager-arrow arrow-left'}/>
                            }
                            nextLabel={
                                <span className={'pager-arrow arrow-right'}/>
                            }
                            onPageChange={({ selected }) => {
                                const page = selected + 1
                                const path = page === 1 ? `/${keyPagination}/` : `/${keyPagination}/${page}/`
                                navigate(path)
                            }}
                            disableInitialCallback
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageContext.totalPages}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={rangeDisplayed}
                            containerClassName={"pager blog-pager flex-justify-sb pager-list flex-container"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            initialPage={pageContext.page - 1}
                            pageClassName={"pager-item flex-center"}
                            hrefBuilder={(page) => {
                                prevNextPaginationButtons(paginateButtons);
                                return `/${keyPagination}/${page}/`;
                            }}
                        />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query CaseStadyQuery($offset: Int!, $perPage: Int!) {
        caseStudy: allWpCaseStudy(
            limit: $perPage
            skip: $offset
        ) {
            nodes {
                id
                slug
                title
                content
                link
                contentType {
                    node {
                        graphqlSingleName
                        name
                    }
                }
                caseStudySingleFeatured {
                    additionalFields {
                        titleH5
                        clientFigures
                    }
                }
                caseStudyRegions {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyIndustries {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyTechnologies {
                    nodes {
                        id
                        name
                    }
                }
                info {
                    aboutClient
                    aboutClientShort
                    brand
                    result
                    gridImage {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 70) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                    }
                }
            }
        }

        customFields: wpPage(slug: {eq: "case-studies"}) {
            title
            caseStudiesCustomFields {
                caseTitleLargeContent {
                    button
                    fieldGroupName
                    title
                    link {
                        fieldGroupName
                        text
                        url
                    }
                    backgroundColor
                    textColor
                    buttonSize
                }
            }
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage{
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }
    }`
